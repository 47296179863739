import userService from '@/service/user.service';
import { defaultUserStoreState } from './userStore';

export const getInitialLoggedUser = async (headers?: any) => {
    let user = defaultUserStoreState.user;
    try {
        user = (await userService.getMe(headers)).data.result;
    } catch (error) {
        // .
    }

    return user;
};

export const logoutUser = async (userId: number, callback: any) => {
    await userService.logoutUser(userId);

    if (callback) {
        callback(defaultUserStoreState.user);
    }
};

export const loginUser = async (data: number, callback: any) => {
    try {
        const {
            data: { result: user },
        } = await userService.loginUser(data);

        if (callback) {
            await callback(user);
        }
    } catch (error) {
        // .
    }
};

export const registerUser = async (data: number, callback: any) => {
    try {
        const {
            data: { result: user },
        } = await userService.registerUser(data);

        if (callback) {
            await callback(user);
        }
    } catch (error) {
        // .
    }
};

export const getUserByUsername = async (username?: string, headers?: any) => {
    let user = defaultUserStoreState.user;
    try {
        user = (await userService.getUserByUsername(username, headers)).data.result;
    } catch (error) {
        // .
    }

    return user;
};

export const updateUserProfile = async (userId: number, userData: any, callback?: any) => {
    const {
        data: { result: user },
    } = await userService.updateUserProfile(userId, userData);

    if (callback) {
        await callback(user);
    }
};
